import {
  dateCompare,
  formatDate,
  formatTime,
} from "../../utils/dateTimeFormat";
import { documentType, mediaType } from "../../utils/sentCommunication";
import Translate from "../../utils/Translate";
import TooltipFieldsList from "../TooltipFieldsList";
import { actionStatus } from "./AfterPickAction";

export function getFreightActionTooltip(freightOrderLines) {
  return freightOrderLines.length > 0 ? (
    <TooltipFieldsList
      items={freightOrderLines.map((ol) => ({
        fields: [
          { label: Translate.get("PartLabel"), value: ol.part.description },
          { label: Translate.get("Price"), value: ol.price },
        ],
      }))}
    />
  ) : null;
}

export function getDeliveryNotificationEmailActionProps(sentCommunications) {
  const sentDeliveryNotifications = sentCommunications
    .filter((sc) => sc.documentType === documentType.deliveryNotification)
    .sort((a, b) => dateCompare(a.sendDate, b.sendDate));
  const hasDone = sentDeliveryNotifications.some((sc) => !sc.bounced);
  const hasFailed = sentDeliveryNotifications.some((sc) => !!sc.bounced);
  const isDone = hasDone && !hasFailed;
  const isFailed = hasFailed && !hasDone;
  const isIndeterminate = hasDone && hasFailed;
  const status = isIndeterminate
    ? actionStatus.indeterminate
    : isDone
    ? actionStatus.done
    : isFailed
    ? actionStatus.failed
    : actionStatus.notDone;

  const tooltip =
    sentDeliveryNotifications.length > 0 ? (
      <TooltipFieldsList
        items={sentDeliveryNotifications.map((s) => ({
          fields: [
            { label: Translate.get("Date"), value: formatDate(s.sendDate) },
            { label: Translate.get("Time"), value: formatTime(s.sendDate) },
            { label: Translate.get("Receiver"), value: s.actorName },
            {
              label: Translate.get("Status"),
              value: (s.bounced
                ? Translate.get("Failed")
                : Translate.get("Ok")
              ).toLowerCase(),
            },
          ],
        }))}
      />
    ) : (
      Translate.get("NotDone")
    );

  return { status, tooltip };
}

export function getDeliveryNoteEmailActionProps(sentCommunications) {
  const sentEmailDeliveryNotes = sentCommunications
    .filter(
      (sc) =>
        sc.documentType === documentType.deliveryNote &&
        sc.media === mediaType.email
    )
    .sort((a, b) => dateCompare(a.sendDate, b.sendDate));
  const hasDone = sentEmailDeliveryNotes.some((sc) => !sc.bounced);
  const hasFailed = sentEmailDeliveryNotes.some((sc) => !!sc.bounced);
  const isDone = hasDone && !hasFailed;
  const isFailed = hasFailed && !hasDone;
  const isIndeterminate = hasDone && hasFailed;
  const status = isIndeterminate
    ? actionStatus.indeterminate
    : isDone
    ? actionStatus.done
    : isFailed
    ? actionStatus.failed
    : actionStatus.notDone;

  const tooltip =
    sentEmailDeliveryNotes.length > 0 ? (
      <TooltipFieldsList
        items={sentEmailDeliveryNotes.map((s) => ({
          fields: [
            { label: Translate.get("Date"), value: formatDate(s.sendDate) },
            { label: Translate.get("Time"), value: formatTime(s.sendDate) },
            { label: Translate.get("Receiver"), value: s.actorName },
            {
              label: Translate.get("Status"),
              value: (s.bounced
                ? Translate.get("Failed")
                : Translate.get("Ok")
              ).toLowerCase(),
            },
          ],
        }))}
      />
    ) : (
      Translate.get("NotDone")
    );

  return { status, tooltip };
}

export function getDeliveryNotePrintActionProps(sentCommunications) {
  const sentPrintDeliveryNotes = sentCommunications
    .filter(
      (sc) =>
        sc.documentType === documentType.deliveryNote &&
        sc.media === mediaType.print
    )
    .sort((a, b) => dateCompare(a.sendDate, b.sendDate));
  const status =
    sentPrintDeliveryNotes.length > 0
      ? actionStatus.done
      : actionStatus.notDone;

  const tooltip =
    sentPrintDeliveryNotes.length > 0 ? (
      <TooltipFieldsList
        items={sentPrintDeliveryNotes.map((s) => ({
          fields: [
            { label: Translate.get("Date"), value: formatDate(s.sendDate) },
            { label: Translate.get("Time"), value: formatTime(s.sendDate) },
            // { label: Translate.get("Receiver"), value: s.actorName },
            {
              label: Translate.get("Status"),
              value: Translate.get("Ok").toLowerCase(),
            },
          ],
        }))}
      />
    ) : (
      Translate.get("NotDone")
    );

  return { status, tooltip };
}

export function getOrderConfirmationEmailActionProps(sentCommunications) {
  const sentOrderConfirmations = sentCommunications
    .filter(
      (sc) =>
        sc.documentType === documentType.orderConfirmation &&
        sc.media === mediaType.email
    )
    .sort((a, b) => dateCompare(a.sendDate, b.sendDate));
  const hasDone = sentOrderConfirmations.some((sc) => !sc.bounced);
  const hasFailed = sentOrderConfirmations.some((sc) => !!sc.bounced);
  const isDone = hasDone && !hasFailed;
  const isFailed = hasFailed && !hasDone;
  const isIndeterminate = hasDone && hasFailed;
  const status = isIndeterminate
    ? actionStatus.indeterminate
    : isDone
    ? actionStatus.done
    : isFailed
    ? actionStatus.failed
    : actionStatus.notDone;

  const tooltip =
    sentOrderConfirmations.length > 0 ? (
      <TooltipFieldsList
        items={sentOrderConfirmations.map((s) => ({
          fields: [
            { label: Translate.get("Date"), value: formatDate(s.sendDate) },
            { label: Translate.get("Time"), value: formatTime(s.sendDate) },
            { label: Translate.get("Receiver"), value: s.actorName },
            {
              label: Translate.get("Status"),
              value: (s.bounced
                ? Translate.get("Failed")
                : Translate.get("Ok")
              ).toLowerCase(),
            },
          ],
        }))}
      />
    ) : (
      Translate.get("NotDone")
    );

  return { status, tooltip };
}
