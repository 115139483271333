import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../../components/DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import download from "downloadjs";
import PrintIcon from "@mui/icons-material/Print";
import DigitalLicenseView from "./DigitalLicenseView";
import { dateCompare, tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";
import { Typography } from "@mui/material";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import {
  canShareDigitalLicense,
  shareDigitalLicenseOrCopyLink,
} from "./digitalLicenseHelper";
import { useCallback, useMemo, useState } from "react";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { ContentCopy, ShoppingCart } from "@mui/icons-material";

function getPdfUrl(license) {
  if (hasAccess(accessKeys.digitalLicensesHolder)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf?dataId=${license.dataId}`;
  } else if (hasAccess(accessKeys.digitalLicensesPublic)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf/public`;
  } else if (hasAccess(accessKeys.digitalLicensesContact)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf/contact`;
  }
}

export default function DigitalLicenseListItem({
  license,
  onClick,
  showDigitalPermitButton,
  skillGroupDescription,
  isRestrictedView,
}) {
  const [expired] = useState(
    license.validTo && dateCompare(license.validTo, Date.now()) < 0
  );
  const navigate = useNavigate();
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const downloadPdf = useCallback(async () => {
    const blob = await api.fetchBlob(getPdfUrl(license), false, "GET");
    const suffix = hasAccess(accessKeys.digitalLicensesContact)
      ? license.displayData.fieldValues["StudentName"]
      : tryFormatDate(license.validFrom);
    const filename = `${Translate.get(
      "DigitalLicenseLabel"
    )}_${suffix}.pdf`.replace(" ", "_");
    download(blob, filename, blob.type);
  }, [api, license]);

  const dotMenuActions = useMemo(
    () => [
      ...(hasAccess(accessKeys.digitalLicensesHolder)
        ? [
            {
              icon: canShareDigitalLicense() ? <ShareIcon /> : <ContentCopy />,
              text: canShareDigitalLicense()
                ? Translate.get("Share")
                : Translate.get("CopyLink"),
              disabled: expired,
              onClick: () =>
                shareDigitalLicenseOrCopyLink(
                  license.publicShareUrl,
                  enqueueSnackbar,
                  closeSnackbar
                ),
            },
          ]
        : []),
      {
        icon: <DownloadIcon />,
        text: Translate.get("Download"),
        disabled: expired,
        onClick: downloadPdf,
      },
    ],
    [
      api,
      navigate,
      license,
      enqueueSnackbar,
      closeSnackbar,
      downloadPdf,
      expired,
    ]
  );

  return (
    <div className="dlcPreCard">
      <DigitalLicenseView
        displayData={license.displayData}
        // We  don't want to show QR code here
        // publicShareUrl={license.publicShareUrl}
        animate={false}
        allowSwiper={false}
        showExtraInfo={false}
        isSmallCard={true}
        expired={expired}
      />

      <div className="stdFlexBetween">
        <div className="dlcHeading2">
          {license.displayData.fieldValues.LicenseGroupDescription}
        </div>
        <div>
          <DotMenu horizontal content={dotMenuActions} />
        </div>
      </div>
      <div className="iconTextRows">
        <div>
          <div>
            <EventAvailableIcon />
          </div>
          <div>{tryFormatDate(license.validFrom)}</div>
        </div>
        <div>
          <div>
            <DomainIcon />
          </div>
          <div>{license.courseCoordinatorName}</div>
        </div>
        <div>
          <div>
            <CoPresentIcon />
          </div>
          <div>{license.instructorName}</div>
        </div>
      </div>
      {hasAccess(accessKeys.digitalLicensesHolder) && (
        <div>
          <Button
            onClick={() =>
              navigate(
                `/d/licenses/${license.digitalLicenseId}/${license.dataId}`,
                {
                  replace: false,
                }
              )
            }
            variant="contained"
            fullWidth={true}
            startIcon={<RemoveRedEyeIcon />}
          >
            {Translate.get("Show")}
          </Button>
        </div>
      )}
    </div>
  );
}
