import { isMobile } from "is-mobile";
import Translate from "../../utils/Translate";
import Api from "../../utils/Api";

export async function addLicenseToGoogleWallet(api, digitalLicenseId, dataId) {
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}wallet/google/digital-license?digitalLicenseId=${digitalLicenseId}&dataId=${dataId}`,
    false,
    "GET"
  );
  if (response.isSuccessful) {
    window.open(response.addToWalletUrl, "_blank", "rel=noopener noreferrer");
  }
}

export async function addLicenseToAppleWallet(
  digitalLicenseId,
  dataId,
  enqueueSnackbar,
  closeSnackbar
) {
  if (!isMobile({ tablet: true, featureDetect: true })) {
    const errorMessage = enqueueSnackbar(
      Translate.get("OnlyMobileSupportBody"),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );
    return;
  }

  const blob = await Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}wallet/apple/digital-license?digitalLicenseId=${digitalLicenseId}&dataId=${dataId}`,
    false,
    "GET"
  );
  var a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `digitallicense${digitalLicenseId}.pkpass`;
  a.click();
}

export function canShareDigitalLicense() {
  return (
    isMobile({ tablet: true, featureDetect: true }) && navigator.canShare()
  );
}

export async function shareDigitalLicenseOrCopyLink(
  publicShareUrl,
  enqueueSnackbar,
  closeSnackbar
) {
  if (isMobile({ tablet: true, featureDetect: true }) && navigator.canShare()) {
    await navigator.share({
      title: Translate.get("DigitalLicenseLabel"),
      url: publicShareUrl,
    });
  } else {
    navigator.clipboard.writeText(publicShareUrl);
    const infoMessage = enqueueSnackbar(Translate.get("CopiedToClipboard"), {
      variant: "info",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(infoMessage),
    });
  }
}
