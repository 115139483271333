import * as Yup from "yup";
import { useState, useRef, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LanguageIcons from "./LanguageIcons";
// material
import Alert from "@mui/material/Alert";
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Grow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import BankIdIcon from "../../bankId/BankIdIcon";

function clearSessionToken() {
  sessionStorage.removeItem("apiRefreshToken");
  sessionStorage.removeItem("apiToken");
  sessionStorage.removeItem("apiTokenExpires");
}

const EmailLoginFrom = ({ onLoginSuccess }) => {
  const [loginFail, setloginFail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const emailFieldRef = useRef();
  const passwordFieldRef = useRef();
  const navigate = useNavigate();
  const api = useApi();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string()
      .trim()
      .email(Translate.get("InvalidEmail"))
      .required(Translate.get("EmailRequired")),
    password: Yup.string().required(Translate.get("PasswordRequired")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      clearSessionToken();

      const req = {
        username: formik.values.email,
        password: formik.values.password,
      };

      api
        .fetch(`${process.env.REACT_APP_AUTH_URL}auth/login/courseadmin`, req)
        .then((data) => {
          if (data.isSuccessful === false) {
            setloginFail(data.errorMessageTranslationKey);
            setloginFail(data.errorMessageTranslationKey);
          if(data.errorMessageTranslationKey == "InvalidPassword") {
            passwordFieldRef.current.focus();
            passwordFieldRef.current.select();
          } else {
            emailFieldRef.current.focus();
            emailFieldRef.current.select();
          }
            formik.setSubmitting(false);
            //navigate('/', { replace: true });
          } else if (data.requirePasswordChange === true) {
            sessionStorage.setItem("cpToken", data.changePasswordToken.token);
            //console.log("Req pw change");
            navigate("/register", { replace: true });
          } else {
            localStorage.setItem("eportLoginMethod", "email"); // spara för att visa epostinloggning vid nästa inloggning
            onLoginSuccess({...data, email: formik.values.email});
          }
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const showBankIdLoginLink = Number(localStorage.lang) === 29; // visa bara länk till bankId-login om svenska är valt

  useEffect(() => setloginFail(""), [values]); // reset login fail when form values changes

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="loginForm"
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="username"
            type="text" // email
            label={Translate.get("Email")} // Email address
            onFocus={(evt) => evt.target.select()}
            inputProps={{ autoCapitalize: "off", spellCheck: false }} // avoid first character uppercase on mobile
            {...getFieldProps("email")}
            inputRef={emailFieldRef}
            error={Boolean(touched.email && errors.email)}
            helperText={(touched.email && errors.email) || " "} // the || ' ' is there to avoid form jumping when helper text is set
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label={Translate.get("Password")}
            inputRef={passwordFieldRef}
            onFocus={(evt) => evt.target.select()}
            {...getFieldProps("password")}
            inputProps={{ spellCheck: false }} // yes, inputProps property seems to exist with different casing and it seems to matter
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={(touched.password && errors.password) || " "} // the || ' ' is there to avoid form jumping when helper text is set
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="left"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Button
            fullWidth={false}
            variant="text"
            size="small"
            component={RouterLink}
            to="/forgotpw"
          >
            {Translate.get("ForgotYourPassword")}
          </Button>
        </Stack>
        {!window.global.isKs && <LanguageIcons isPreLogin={true} />}
        <LoadingButton
          className="stdBlueLoader"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {Translate.get("LogIn")}
        </LoadingButton>
        <Grow
          in={loginFail ? true : false}
          style={{ transformOrigin: "0 0 0", marginTop: loginFail ? "1em" : "" }}
          {...(loginFail ? { timeout: 1000 } : {})}
        >
          <Alert severity="error">
            {loginFail && Translate.get(loginFail)}
          </Alert>
        </Grow>
        {showBankIdLoginLink && (
          <Button
            href="/login/bankId"
            startIcon={<BankIdIcon width={40} />}
            sx={{ marginTop: "2em" }}
          >
            {Translate.get("LogInUsingBankId")}
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
};

export default EmailLoginFrom;
