import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useCallback, useEffect, useState } from "react";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { useApi } from "../../utils/Api";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Preloader from '../Preloader';


export default function AddOrUpdateAddressDialog({
    existingAddress,
    actorId,
    mode,
    onCancel,
    showSelectAddressType,
    handleCloseAddOrUpdateAddressDialog
}) {

    const [actorAddressStreet1, setActorAddressStreet1] = useState("");
    const [actorAddressStreet2, setActorAddressStreet2] = useState("");
    const [actorAddressZipCode, setActorAddressZipCode] = useState("");
    const [actorAddressCity, setActorAddressCity] = useState("");
    const [actorAddressCountry, setActorAddressCountry] = useState("");
    const [actorAddressTypeId, setActorAddressTypeId] = useState("");
    const [actorAddressId, setActorAddressId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [countries, setCountries] = useState([]);
    const adressTypes = [1, 2, 3, 4]


    const api = useApi();

    const countrySearch = useCallback(
        async (searchText) => {
            const lang = localStorage.lang ?? 29;
            const search = encodeURIComponent(searchText);
            const url = `${process.env.REACT_APP_MAIN_URL}countries?searchText=${search}&languageCode=${lang}`;
            const result = await api.fetch(url, false, "GET");
            result.sort((a, b) =>
                a.alpha2 === "SE"
                    ? -1
                    : b.alpha2 === "SE"
                        ? 1
                        : a.name.localeCompare(b.name)
            );
            return result;
        },
        [api]
    );

    useEffect(() => {
        if (existingAddress != null) {
            setActorAddressStreet1(existingAddress.actorAddressStreet1);
            setActorAddressStreet2(existingAddress.actorAddressStreet2);
            setActorAddressZipCode(existingAddress.actorAddressZipCode);
            setActorAddressCity(existingAddress.actorAddressCity);
            setActorAddressCountry(existingAddress.actorAddressCountry);
            setActorAddressTypeId(existingAddress.actorAddressType);
            setActorAddressId(existingAddress.actorAddressId);
        }
    }, []);

    useEffect(() => {
        countrySearch("").then((result) => setCountries(result));
    }, [countrySearch]);


    function handleSave() {
        if (mode === "edit") {
            putAddress();
        } else {
            postAddress();
        }
    }

    async function putAddress() {
        setIsLoading(true);

        let reqObj =
        {
            ActorAddressId: actorAddressId,
            ActorId: actorId,
            AddressStreet1: actorAddressStreet1,
            AddressStreet2: actorAddressStreet2,
            ZipCode: actorAddressZipCode,
            City: actorAddressCity,
            Country: actorAddressCountry,
            AddressType: actorAddressTypeId
        };


        let response = null;
        try {
            response = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}actors/address`,
                reqObj,
                'PUT',
            );

            if (response.isSuccessful) {
                await handleCloseAddOrUpdateAddressDialog();
            }

        } finally {
            setIsLoading(false);
        }
    }

    async function postAddress() {
        setIsLoading(true);

        let reqObj =
        {
            ActorId: actorId,
            AddressStreet1: actorAddressStreet1,
            AddressStreet2: actorAddressStreet2,
            ZipCode: actorAddressZipCode,
            City: actorAddressCity,
            Country: actorAddressCountry,
            AddressType: actorAddressTypeId
        };
    
    
        let response = null;
        try {
          response = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}actors/address`,
            reqObj,
            'POST',
          );
    
          if (response.isSuccessful) {
            await handleCloseAddOrUpdateAddressDialog();
          }
    
        } finally {
          setIsLoading(false);
        }
      }

    function getTitle() {
        switch (mode) {
            case "edit":
                return Translate.get("EditAddress");
            case "clone":
            case "add":
                return Translate.get("AddAddress");
            default:
                return "";
        }
    }

    return (
        <Dialog open={true} className="smallCourseDialog">
            <DialogTitle>{getTitle()}</DialogTitle>
            <DialogContent>
                {isLoading && <Preloader />}
                <Grid container direction="column" spacing={2} marginTop={0.1}>
                    <Grid item>
                        <TextField
                            fullWidth
                            value={actorAddressStreet1}
                            InputProps={{ spellCheck: false }}
                            onChange={(event) => setActorAddressStreet1(event.target.value)}
                            label={Translate.get("Address1")}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            value={actorAddressStreet2}
                            InputProps={{ spellCheck: false }}
                            onChange={(event) => setActorAddressStreet2(event.target.value)}
                            label={Translate.get("Address2")}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                value={actorAddressZipCode}
                                InputProps={{ spellCheck: false }}
                                onChange={(event) => setActorAddressZipCode(event.target.value)}
                                label={Translate.get("ZipCode")}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                fullWidth
                                value={actorAddressCity}
                                InputProps={{ spellCheck: false }}
                                onChange={(event) => setActorAddressCity(event.target.value)}
                                label={Translate.get("AddressCity")}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/* Some addresses will have real country names, others have some other kind of data.
                              Here we show a list of existing countries if the value matches one or is empty.
                              But for all existing addresses with other content we will show a text field. */}
                        {!actorAddressCountry?.trim() ||
                            countries.find((c) => c.name === actorAddressCountry) ? (
                            <AutocompleteSearchField
                                requireSelection
                                disableAddAsNew
                                label={Translate.get("Country")}
                                defaultOptions={countries}
                                value={
                                    actorAddressCountry && countries && countries.length > 0
                                        ? countries.find((c) => c.name === actorAddressCountry)
                                        : ""
                                }
                                onValueChange={(option) => setActorAddressCountry(option?.name)}
                                getOptionLabel={(option) =>
                                    option ? `${option.name} (${option.alpha2})` : ""
                                }
                                renderOption={(option) => `${option.name} (${option.alpha2})`}
                                keyPropName="name"
                                search={countrySearch}
                                textFieldProps={{
                                    required: false,
                                }}
                                hideOptionsFilter={
                                    actorAddressCountry &&
                                    ((option) => option.name === actorAddressCountry.trim())
                                }
                            />
                        ) : (
                            <TextField
                                fullWidth
                                value={actorAddressCountry}
                                onChange={(event) => setActorAddressCountry(event.target.value)}
                                label={Translate.get("Country")}
                                inputProps={{ maxLength: 50 }}
                            />
                        )}

                        {showSelectAddressType === true && <FormControl fullWidth style={{ marginTop: "1em" }}>
                            <InputLabel id="adress-type-label">{Translate.get("AddressType")}</InputLabel>
                            <Select
                                labelId="adress-type-label"
                                label={Translate.get("AddressType")}
                                value={actorAddressTypeId}
                                onChange={(event) => setActorAddressTypeId(event.target.value)}
                            >
                                {adressTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {Translate.getActorAddressType(type)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onCancel}>
                    {Translate.get("Cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleSave()}
                    disabled={!actorAddressStreet1 || !actorAddressStreet2 || !actorAddressZipCode || !actorAddressCity
                        || (showSelectAddressType && !actorAddressTypeId)}>
                    {Translate.get("Save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
