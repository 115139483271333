import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import DotMenu from "../DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import download from "downloadjs";
import PrintIcon from "@mui/icons-material/Print";
import DigitalLicenseView from "./DigitalLicenseView";
import { dateCompare, tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  addLicenseToAppleWallet,
  addLicenseToGoogleWallet,
  canShareDigitalLicense,
  shareDigitalLicenseOrCopyLink,
} from "./digitalLicenseHelper";
import { useCallback, useMemo, useState } from "react";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { ContentCopy, Loop } from "@mui/icons-material";

function getPdfUrl(license) {
  if (hasAccess(accessKeys.digitalLicensesHolder)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf?dataId=${license.dataId}`;
  } else if (hasAccess(accessKeys.digitalLicensesPublic)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf/public`;
  } else if (hasAccess(accessKeys.digitalLicensesContact)) {
    return `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${license.digitalLicenseId}/pdf/contact`;
  }
}

export default function DigitalLicenseSingleItem({
  license,
  onClick,
  showDigitalPermitButton,
  skillGroupDescription,
  isRestrictedView,
  company,
  skills,
}) {
  const [expired] = useState(
    license.validTo && dateCompare(license.validTo, Date.now()) < 0
  );
  const navigate = useNavigate();
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const smallScreen = useMediaQuery("(max-width:750px)"); // Same breakpoint as in DigitalLicenseView

  const downloadPdf = useCallback(async () => {
    const blob = await api.fetchBlob(getPdfUrl(license), false, "GET");
    const suffix = tryFormatDate(license.validFrom);
    const filename = `${Translate.get(
      "DigitalLicenseLabel"
    )}_${suffix}.pdf`.replace(" ", "_");
    download(blob, filename, blob.type);
  }, [api, license]);

  const dotMenuActions = useMemo(
    () => [
      ...(hasAccess(accessKeys.digitalLicensesHolder)
        ? [
            {
              icon: <ListAltIcon />,
              text: Translate.get("ShowActorPermit"),
              onClick: () => navigate(`/d/permits/${skillGroupDescription}`),
            },
            {
              icon: <AddIcon />,
              text: Translate.get("AddToAppleWallet"),
              disabled: expired,
              onClick: () =>
                addLicenseToAppleWallet(
                  license.digitalLicenseId,
                  license.dataId,
                  enqueueSnackbar,
                  closeSnackbar
                ),
            },
            {
              icon: <AddIcon />,
              text: Translate.get("AddToGoogleWallet"),
              disabled: expired,
              onClick: () =>
                addLicenseToGoogleWallet(
                  api,
                  license.digitalLicenseId,
                  license.dataId
                ),
            },
            {
              icon: <AddShoppingCartIcon />,
              text: Translate.get("PlaceOrder"),
              disabled: expired || license.isArchived,
              onClick: () =>
                navigate(
                  `/d/order/${license.digitalLicenseId}/${license.dataId}`
                ),
            },
            {
              icon: canShareDigitalLicense() ? <ShareIcon /> : <ContentCopy />,
              text: canShareDigitalLicense()
                ? Translate.get("Share")
                : Translate.get("CopyLink"),
              disabled: expired,
              onClick: () =>
                shareDigitalLicenseOrCopyLink(
                  license.publicShareUrl,
                  enqueueSnackbar,
                  closeSnackbar
                ),
            },
          ]
        : []),
      {
        icon: <DownloadIcon />,
        text: Translate.get("Download"),
        disabled: expired,
        onClick: downloadPdf,
      },
    ],
    [
      api,
      navigate,
      license,
      enqueueSnackbar,
      closeSnackbar,
      downloadPdf,
      expired,
      skillGroupDescription,
    ]
  );

  return (
    <div className="dlSingleContainer">
      <div className="stdFlexSB">
        <div>
          {hasAccess(accessKeys.digitalLicensesHolder) && (
            <IconButton onClick={() => window.history.back()}>
              <ArrowBackIcon />
            </IconButton>
          )}
        </div>
        <div>
          <DotMenu horizontal content={dotMenuActions} />
        </div>
      </div>
      {/* TODO Kan vi göra oss av med dlcCardWrapper och förenkla denna lite? */}
      {smallScreen ? (
        <DigitalLicenseView
          displayData={license.displayData}
          publicShareUrl={license.publicShareUrl}
          company={company}
          skills={skills}
          animate={true}
          allowSwiper={true}
          showExtraInfo={true}
          isSmallCard={false}
          expired={expired}
        />
      ) : (
        <div className="dlcCardWrapper">
          <DigitalLicenseView
            displayData={license.displayData}
            publicShareUrl={license.publicShareUrl}
            company={company}
            skills={skills}
            animate={true}
            allowSwiper={true}
            showExtraInfo={true}
            isSmallCard={false}
            expired={expired}
          />
        </div>
      )}
      {smallScreen && (
        <Box display="flex" justifyContent="center" marginTop="10px">
          <Typography
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              color: "text.secondary",
              fontSize: "12px",
            }}
          >
            <Loop />
            {Translate.get("SwipeToFlipCard")}
          </Typography>
        </Box>
      )}
    </div>
  );
}
