import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useApi } from "../../utils/Api";
import { useState } from "react";
import Preloader from "../Preloader";
import { useEffect } from "react";

export default function ChangeCustomerOrderNoDialog({
  orderId,
  onClose,
  onSaved,
}) {
  const [customerOrderNo, setCustomerOrderNo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const api = useApi();

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/customer-order-no`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setCustomerOrderNo(response.customerOrderNo);
      }
      setIsLoading(false);
    }
    init();
  }, [api]);

  async function handleSave() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/customer-order-no`,
      { customerOrderNo },
      "POST"
    );
    if (response.isSuccessful) {
      await onSaved();
      onClose();
    }
  }

  return (
    <>
      {isLoading && <Preloader />}
      <Dialog
        open
        onClose={onClose}
        maxWidth={false}
        className="smallCourseDialog"
      >
        <DialogTitle>
          {Translate.get("ChangeCustomerOrderNoDescription")}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ marginTop: "6px" }}
            label={Translate.get("DefaultCustomerOrderNo")}
            value={customerOrderNo ?? ""}
            onChange={(event) => setCustomerOrderNo(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <ButtonWithSpinner onClick={handleSave}>
            {Translate.get("Save")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}
