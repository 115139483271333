import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import Translate from "../../utils/Translate";
import { useState } from "react";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import { QRCodeSVG } from "qrcode.react";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import { Avatar, useMediaQuery } from "@mui/material";
import PreviewCompany from "./PreviewCompany";
import PreviewCompetences from "./PreviewCompetences";
import { useEffect } from "react";
import { Warning } from "@mui/icons-material";
import QRCodePreview from "./QRCodePreview";

const Frontside = ({
  bgImg,
  bgAnimation,
  fields,
  primaryFontColor,
  secondaryFontColor,
}) => {
  //console.log("Cardfront", props);
  return (
    <>
      {bgImg && (
        <>
          <img className="dlcBgImg" src={bgImg} />
        </>
      )}
      {bgAnimation ? (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      ) : (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlstatic.svg"
        ></object>
      )}
      <div className="dlcFrontText">
        {fields.map((row, index) => (
          <Fragment key={index}>
            {row.fieldName === "CompanyLogo" ? (
              row.value &&
              row.value != "-" && (
                <div>
                  <img src={row.value} className="dlcCompanyLogo" />
                </div>
              )
            ) : row.fieldName === "ProfilePicture" ? (
              <div className="dlcProfilePicContainer">
                {row.value && row.value != "-" && (
                  <img src={row.value} className="dlcProfilePic" />
                )}
              </div>
            ) : row.fieldName === "gap" ? (
              <div className="dlcGap"></div>
            ) : (
              <div>
                <div
                  style={
                    secondaryFontColor ? { color: secondaryFontColor } : {}
                  }
                >
                  {Translate.get(row.titleTranslationKey)}
                </div>
                <div
                  style={{
                    fontSize: row.fontSize,
                    ...(primaryFontColor ? { color: primaryFontColor } : {}),
                  }}
                  className={
                    row.fieldName === "LicenseGroupDescription"
                      ? "dlcMainTitle"
                      : "dlcHeading1"
                  }
                >
                  {row.value}
                </div>
                <div
                  style={
                    secondaryFontColor ? { color: secondaryFontColor } : {}
                  }
                >
                  {row.subtitleTranslationKey &&
                    Translate.get(row.subtitleTranslationKey)}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

const Backside = ({
  bgImg,
  bgAnimation,
  fields,
  primaryFontColor,
  secondaryFontColor,
  publicShareUrl,
  showExtraInfo,
  handleOpenCompany,
  handleOpenComp,
}) => {
  function renderFieldValue(row) {
    if (
      (row.fieldName === "CourseCoordinator" ||
        row.fieldName === "ActorLicenseAllSkills") &&
      showExtraInfo
    ) {
      return (
        <div
          className="selectable"
          onClick={
            row.fieldName === "CourseCoordinator"
              ? handleOpenCompany
              : handleOpenComp
          }
        >
          <div>{row.value}</div>
          <InfoOutlinedIcon className="dlInfoIcon" />
        </div>
      );
    } else if (
      row.fieldName === "ValidTo" &&
      showField(row.fieldName, row.value) &&
      new Date(row.value) < new Date()
    ) {
      return (
        <div style={primaryFontColor ? { color: primaryFontColor } : {}}>
          <div>{row.value}</div>
          <Warning className="warningColor" />
        </div>
      );
    } else {
      return (
        <div style={primaryFontColor ? { color: primaryFontColor } : {}}>
          {row.value}
        </div>
      );
    }
  }

  return (
    <>
      {bgImg && (
        <>
          <img className="dlcBgImg" src={bgImg} />
        </>
      )}
      {bgAnimation ? (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      ) : (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlstatic.svg"
        ></object>
      )}
      <div className="dlcBackText">
        {fields.map((row, index) => (
          <div key={index}>
            <div
              style={secondaryFontColor ? { color: secondaryFontColor } : {}}
            >
              {Translate.get(row.titleTranslationKey)}
            </div>
            {renderFieldValue(row)}
          </div>
        ))}

        {publicShareUrl && (
          <div className="dlcQrCodeContainer">
            {publicShareUrl !== "preview" ? (
              <QRCodeSVG
                id="qr-code"
                value={publicShareUrl}
                size={90}
                level="L"
                marginSize={1}
              />
            ) : (
              <QRCodePreview size={90} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

function showField(fieldName, value) {
  switch (fieldName) {
    case "ValidTo":
      return !!value;
    default:
      return true;
  }
}

export default function DigitalLicenseView({
  displayData,
  publicShareUrl,
  animate,
  allowSwiper,
  company,
  skills,
  showExtraInfo,
  isSmallCard,
  expired,
}) {
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [showCompetencesDialog, setShowCompetencesDialog] = useState(false);
  const [frontFields, setFrontFields] = useState([]);
  const [backFields, setBackFields] = useState([]);
  const [frontBgColor, setFrontBgColor] = useState({});
  const [backBgColor, setBackBgColor] = useState({});

  const smallScreen = useMediaQuery("(max-width:750px)");

  useEffect(() => {
    const ff = displayData.frontFields.map((row) => {
      const value = displayData.fieldValues[row.fieldName]
        ? displayData.fieldValues[row.fieldName]
        : "-";
      return { ...row, value };
    });
    setFrontFields(ff);

    const bf = displayData.backFields
      .filter((row) =>
        showField(row.fieldName, displayData.fieldValues[row.fieldName])
      )
      .map((row) => {
        let value = displayData.fieldValues[row.fieldName];
        if (row.fieldName === "ValidFrom" || row.fieldName === "ValidTo") {
          value = tryFormatDate(value);
        }

        value = value ? value : "-";
        return { ...row, value };
      });
    setBackFields(bf);

    setFrontBgColor(
      displayData?.digitalLicenseColor
        ? { backgroundColor: displayData.digitalLicenseColor }
        : {}
    );

    setBackBgColor(
      displayData?.digitalLicenseColorAlt
        ? { backgroundColor: displayData.digitalLicenseColorAlt }
        : {}
    );
  }, [displayData]);

  return (
    <>
      {showCompanyDialog && (
        <PreviewCompany
          company={company}
          onClose={() => setShowCompanyDialog(false)}
        />
      )}
      {showCompetencesDialog && (
        <PreviewCompetences
          skills={skills}
          onClose={() => setShowCompetencesDialog(false)}
        />
      )}
      {smallScreen && allowSwiper ? (
        <Swiper
          effect={"flip"}
          grabCursor={true}
          loop={true}
          //pagination={true}
          //navigation={true}
          modules={[EffectFlip]}
          className="swiperDL"
          initialSlide={0}
        >
          <SwiperSlide className="swiperDLSlide" style={frontBgColor}>
            <Frontside
              fields={frontFields}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
              expired={expired}
              primaryFontColor={displayData.primaryFontColor}
              secondaryFontColor={displayData.secondaryFontColor}
            />
          </SwiperSlide>
          <SwiperSlide className="swiperDLSlide" style={backBgColor}>
            <Backside
              fields={backFields}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
              expired={expired}
              publicShareUrl={publicShareUrl}
              primaryFontColor={displayData.primaryFontColorAlt}
              secondaryFontColor={displayData.secondaryFontColorAlt}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className={isSmallCard ? "dlcSmallCard" : "dlcCard"}>
          <div style={frontBgColor}>
            <Frontside
              fields={frontFields}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
              expired={expired}
              primaryFontColor={displayData.primaryFontColor}
              secondaryFontColor={displayData.secondaryFontColor}
            />
          </div>
          <div style={backBgColor}>
            <Backside
              fields={backFields}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
              expired={expired}
              publicShareUrl={publicShareUrl}
              primaryFontColor={displayData.primaryFontColorAlt}
              secondaryFontColor={displayData.secondaryFontColorAlt}
            />
          </div>
        </div>
      )}
    </>
  );
}
