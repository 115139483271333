import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// material
import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import Logo from "../../../components/Logo";
import Avatar from "@mui/material/Avatar";
import BusinessIcon from "@mui/icons-material/Business";
import {
  accessKeys,
  hasAccess,
  resetAccess,
  setAccess,
} from "../../../utils/userAccess";
import LicenseAgreementApprovalDialog from "./LicenseAgreementApprovalDialog";
import { useSnackbar } from "notistack";
import Preloader from "../../../components/Preloader";
import * as Sentry from "@sentry/react";
import BankIdLoginForm from "./BankIdLoginForm";
import EmailLoginFrom from "./EmailLoginForm";
import { useSearchParams } from "react-router-dom";
import { loadActiveIntegrations } from "../../../utils/integrations";

// ----------------------------------------------------------------------

export default function LoginForm({
  redirectPath,
  openBankIdLogin,
  startQrCodeOnDesktop,
}) {
  // if (!openBankIdLogin && window.global.isEcomp) {
  //   throw new Error();
  // }

  sessionStorage.removeItem("courses");
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showLicenseAgreementForUser, setShowLicenseAgreementForUser] =
    useState(null);
  const [token, setToken] = useState(false);
  const [additionalData, setAdditionalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const isDigitalLicenseContact = !!searchParams.get(
    "digitalLicensePublishGroupId"
  );
  const isDigitalLicensePublic = !!searchParams.get("digitalLicenseId");

  useEffect(() => {
    async function loadDigitalLicenseLinkData() {
      setIsLoading(true);
      resetAccess();
      const url = digitalLicensePublishGroupId
        ? `${process.env.REACT_APP_AUTH_URL}auth/digital-licenses/groups?digitalLicensePublishGroupId=${digitalLicensePublishGroupId}&key=${key}`
        : `${process.env.REACT_APP_AUTH_URL}auth/digital-licenses/public?digitalLicenseId=${digitalLicenseId}&key=${key}`;

      const [_, response] = await Promise.all([
        Translate.loadApp(false),
        api.fetch(url, false, "GET"),
      ]);

      if (response.isSuccessful) {
        const { access, token } = response;
        setAccess(access);
        sessionStorage.setItem("apiToken", `Bearer ${token.token}`);
        sessionStorage.setItem("apiTokenExpires", token.expires);
        sessionStorage.setItem("lastConnection", Date.now());
        const pageUrl = digitalLicensePublishGroupId
          ? `/d/groups/${digitalLicensePublishGroupId}/licenses`
          : `/d/licenses/${digitalLicenseId}/0`;
        navigate(pageUrl);
      }
      setIsLoading(false);
    }

    const digitalLicensePublishGroupId = searchParams.get(
      "digitalLicensePublishGroupId"
    );
    const digitalLicenseId = searchParams.get("digitalLicenseId");
    const key = searchParams.get("key");
    const hasParams = key && (digitalLicensePublishGroupId || digitalLicenseId);
    const isLoadNeeded = window.global.isEcomp && hasParams;
    if (isLoadNeeded) {
      loadDigitalLicenseLinkData();
    }
  }, [searchParams, api]);

  async function handleSelectUser(user, accountLoginToken) {
    if (user.hasUnapprovedLicenseAgreements) {
      setShowLicenseAgreementForUser(user);
    } else {
      setIsLoading(true);
      resetAccess();
      //console.log("selectedUser", selectedUser);
      //console.log("accountLoginToken", token);
      // (url, data = false, method = 'POST', token = false, json = true) {
      const data = await api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/login/select-user`,
        user,
        "POST",
        `Bearer ${accountLoginToken}`
      );
      if (data.identityToken !== null && data.identityToken?.token) {
        //console.log(data);
        //console.log(`Bearer ${data.identityToken.token}`);
        // sessionStorage.setItem('apiToken', 'Bearer ' + data.identityToken.token);
        sessionStorage.setItem(
          "apiToken",
          `Bearer ${data.identityToken.token}`
        );
        sessionStorage.setItem("apiTokenExpires", data.identityToken.expires);
        //Logout mess -  see DashboardTimer.js
        sessionStorage.setItem("lastConnection", Date.now());
        ////console.log('Timer reset', Date.now());
        //---------------------------------------

        sessionStorage.setItem("apiRefreshToken", data.refreshToken.token);
        sessionStorage.setItem("companyName", user.companyName);
        Sentry.setUser({
          email: additionalData.email,
          id: user.ePortUserId,
        });
        setAccess(data.access);
        loadActiveIntegrations();
        Translate.loadApp(false).then(() => {
          if (redirectPath) {
            window.location = redirectPath;
          }
          //console.log("redirect start");
          /*  console.log(
              "HAS ACCESS courseAdmin--------------",
              hasAccess(accessKeys.courseAdmin)
            );
            console.log(
              "HAS ACCESS dashboard--------------",
              hasAccess(accessKeys.dashboard)
            ); */
          if (!hasAccess(accessKeys.courseAdmin)) {
            hasAccess(accessKeys.dashboard)
              ? navigate("/dashboard/app", { replace: true })
              : hasAccess(accessKeys.actorPermit)
              ? navigate("/dashboard/app", { replace: true })
              : navigate("/dashboard/licenseregistration", { replace: true });
          } else {
            hasAccess(accessKeys.dashboard)
              ? navigate("/dashboard/app", { replace: true })
              : navigate("/dashboard/courses", { replace: true });
          }
        });
      } else {
        // error
        sessionStorage.removeItem("apiToken");
        const errorMessage = enqueueSnackbar(
          Translate.get(data.errorMessageTranslationKey ?? "SomethingFailed"),
          {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          }
        );
        navigate("/", { replace: true });
      }
    }
  }

  async function handleDigitalLicensesResponse({
    eCompToken,
    hasDigitalLicenses,
    hasDigitalPermits,
    access,
  }) {
    if (!hasDigitalLicenses && !hasDigitalPermits) {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
      navigate("/", { replace: true });
    }
    await Translate.loadApp(false);
    resetAccess();
    setAccess(access);
    sessionStorage.setItem("apiToken", `Bearer ${eCompToken.token}`);
    sessionStorage.setItem("apiTokenExpires", eCompToken.expires);
    sessionStorage.setItem("lastConnection", Date.now());
    sessionStorage.removeItem("apiRefreshToken");
    if (redirectPath) {
      window.location = redirectPath;
    } else {
      navigate(hasDigitalLicenses ? "/d/licenses" : "/d/permits");
    }
  }

  async function onLoginSuccess(data) {
    if (window.global.isEcomp) {
      handleDigitalLicensesResponse(data);
    } else {
      const { name, email, accountLoginToken, users: ePortUsers } = data;
      sessionStorage.setItem("userFullName", name);
      sessionStorage.setItem("userEmail", email);
      sessionStorage.removeItem("logoURL");
      setAdditionalData({ name: name, email: email });
      setToken(accountLoginToken.token);
      if (ePortUsers.length > 1) {
        setUsers(ePortUsers);
      } else {
        handleSelectUser(ePortUsers[0], accountLoginToken.token);
      }
    }
  }

  if (isDigitalLicenseContact || isDigitalLicensePublic) {
    return isLoading ? <Preloader invisible={true} /> : null;
  }

  if (showLicenseAgreementForUser) {
    return (
      <LicenseAgreementApprovalDialog
        user={showLicenseAgreementForUser}
        accountLoginToken={token}
        open={true}
        onClose={() => setShowLicenseAgreementForUser(null)}
        onComplete={() =>
          handleSelectUser(
            {
              ...showLicenseAgreementForUser,
              hasUnapprovedLicenseAgreements: false,
            },
            token
          )
        }
      />
    );
  }

  if (users && users.length > 1) {
    return (
      <>
        {isLoading && <Preloader invisible={true} />}
        <div className="loginBox">
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {Translate.get("SelectUserCompany")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {Translate.get("MultipleAccountsConnected")}
            </Typography>
          </Box>
          <List>
            {users.map((user, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  className="stdListButton"
                  onClick={() => handleSelectUser(user, token)}
                >
                  <ListItemIcon>
                    <Avatar>
                      <BusinessIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.companyName}
                    secondary={parseInt(user.ePortUserId)}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </>
    );
  }

  return (
    <div className="loginBox">
      <div className="loginLogo">
        <Logo />
      </div>
      {!openBankIdLogin && !window.global.isEcomp && (
        <EmailLoginFrom onLoginSuccess={onLoginSuccess} />
      )}
      {(openBankIdLogin || window.global.isEcomp) && (
        <BankIdLoginForm
          onLoginSuccess={onLoginSuccess}
          startQrCodeOnDesktop={startQrCodeOnDesktop}
          onlyBankIdLogin={window.global.isEcomp}
        />
      )}
    </div>
  );
}
