import { useState, useEffect } from 'react';
import { useApi } from '../../../utils/Api';
import Translate from '../../../utils/Translate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, IconButton, Menu, MenuItem } from '@mui/material';
import Preloader from '../../Preloader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddOrUpdateAddressDialog from '../../orders/AddOrUpdateAddressDialog';
import { useNavigate, useLocation } from "react-router-dom";
import { AddCircleOutline } from '@mui/icons-material';

const ShowAllAddresses = ({ actorId }) => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);

  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [mode, setMode] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();


  const api = useApi();

  useEffect(async () => {
    await getAddresses();
  }, []);


  const closeAndReload = () => {
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  async function getAddresses() {
    setIsLoading(true);
    let response = null;
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/addresses`,
        false,
        'GET',
      );

      setAddresses(response.sort((a, b) => a.actorAddressType - b.actorAddressType));

    } finally {
      setIsLoading(false);
    }
  }

  function handleClick(event, index) {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  }

  function handleClose() {
    setAnchorEl(null);
    setMenuIndex(null);
  }

  async function handleCloseAddOrUpdateAddressDialog() {
    setAnchorEl(null);
    setMenuIndex(null);
    setShowAddressDialog(false);
    await getAddresses();
  }


  async function handleDelete(address) {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/address/${address.actorAddressId}`,
        true,
        'DELETE',
      );

      if (response.isSuccessful) {
        await getAddresses();
      }

    } finally {
      setIsLoading(false);
    }

    handleClose();
  }


  async function handleEdit(address) {
    setMode("edit");
    setSelectedAddress(address);
    setShowAddressDialog(true); 
  }

  async function handleAddAdress() {
    setMode("add");
    setSelectedAddress(null);
    setShowAddressDialog(true);
  }

  async function handleClone(address) {
    setMode("clone");
    setSelectedAddress(address);
    setShowAddressDialog(true);
  }

  async function handleCloseAddressDialog() {
    setShowAddressDialog(false);
    handleClose();
  }

  if (isLoading) {
    return (
      <div>
        <Preloader />
      </div>
    )
  }

  if (showAddressDialog) {
    return (
      <AddOrUpdateAddressDialog
        existingAddress={selectedAddress}
        actorId={actorId}
        mode={mode}
        showSelectAddressType={mode !== "edit"}
        onCancel={() => handleCloseAddressDialog()}
        handleCloseAddOrUpdateAddressDialog={() => handleCloseAddOrUpdateAddressDialog()}
        
      />
    );
  }

  return (
    <Dialog
      open={true}
      onClose={closeAndReload}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        {Translate.get("Addresses")}
      </DialogTitle>
      <DialogContent>
        <div>
          {addresses.length < 1 ? (
            Translate.get("AddressEmptyState")
          ) : (
            addresses.map((address, index) => (
              <Card key={index} variant="outlined" style={{ padding: "1em", marginBottom: "1em" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span style={{ backgroundColor: "#EDEFF1", padding: "3px", borderRadius: "3px", fontSize: "smaller" }}>
                    {Translate.getActorAddressType(address.actorAddressType)}
                  </span>
                  <div>
                    <IconButton
                      aria-controls={`simple-menu-${index}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, index)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`simple-menu-${index}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl) && menuIndex === index}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleEdit(address)}>{Translate.get("EditLabel")}</MenuItem>
                      <MenuItem onClick={() => handleClone(address)}>{Translate.get("Clone")}</MenuItem>
                      <MenuItem onClick={() => handleDelete(address)}>{Translate.get("Delete")}</MenuItem>
                    </Menu>
                  </div>
                </div>
                <p>{address.actorAddressStreet1}</p>
                <p>{address.actorAddressStreet2}</p>
                <p>{address.actorAddressZipCode} {address.actorAddressCity}</p>
                <p>{address.actorAddressCountry}</p>
              </Card>
            ))
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between", marginLeft: "1em", marginRight: "1em", marginBottom: "1em" }}>
        <Button onClick={handleAddAdress} variant="outlined" startIcon={<AddCircleOutline />}>
          {Translate.get("AddAddress")}
        </Button>
        <Button onClick={closeAndReload}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowAllAddresses;