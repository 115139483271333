import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const _defaultPageSizeOptions = [10, 20, 30];

const _defaultPaginationModel = {
  pageSize: _defaultPageSizeOptions[0],
  page: 0,
};

function getDefaultPaginationModel(overridePageSizeOptions) {
  return overridePageSizeOptions && overridePageSizeOptions.length > 0
    ? { ..._defaultPaginationModel, pageSize: overridePageSizeOptions[0] }
    : _defaultPaginationModel;
}

const _defaultFilterModel = {
  items: [],
  logicOperator: "and",
};

export function buildQueryModel(paginationModel, sortModel, filterModel, searchText) {
  return {
    searchText: searchText,
    pagination: paginationModel
      ? {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
        }
      : null,
    filter: filterModel
      ? {
          items: filterModel.items.map((f) => ({
            ...f,
            value: f.value && Array.isArray(f.value) ? null : f.value,
            valueArray: f.value && Array.isArray(f.value) ? f.value : null,
          })),
          logicOperator: filterModel.logicOperator,
        }
      : null,
    sort: sortModel
      ? sortModel.map((sm) => ({
          field: sm.field,
          direction: sm.sort,
        }))
      : null,
  };
}

function toPaginationModelKey(paginationStateId) {
  return `Pagination_PaginationModel_${paginationStateId}`;
}

function toSortModelKey(paginationStateId) {
  return `Pagination_SortModel_${paginationStateId}`;
}

function toFilterModelKey(paginationStateId) {
  return `Pagination_FilterModel_${paginationStateId}`;
}

function savePaginationSession(
  paginationStateId,
  paginationModel,
  sortModel,
  filterModel
) {
  if (!paginationStateId) {
    return;
  }

  if (paginationModel) {
    const key = toPaginationModelKey(paginationStateId);
    sessionStorage.setItem(key, JSON.stringify(paginationModel));
  }

  if (sortModel) {
    const key = toSortModelKey(paginationStateId);
    sessionStorage.setItem(key, JSON.stringify(sortModel));
  }

  if (filterModel) {
    const key = toFilterModelKey(paginationStateId);
    sessionStorage.setItem(key, JSON.stringify(filterModel));
  }
}

function loadPaginationSession(paginationStateId) {
  if (!paginationStateId) {
    return {};
  }

  const paginationModel = JSON.parse(
    sessionStorage.getItem(toPaginationModelKey(paginationStateId))
  );
  const sortModel = JSON.parse(
    sessionStorage.getItem(toSortModelKey(paginationStateId))
  );
  const filterModel = JSON.parse(
    sessionStorage.getItem(toFilterModelKey(paginationStateId))
  );

  return { paginationModel, sortModel, filterModel };
}

function clearPaginationSession(paginationStateId) {
  sessionStorage.removeItem(toPaginationModelKey(paginationStateId));
  sessionStorage.removeItem(toSortModelKey(paginationStateId));
  sessionStorage.removeItem(toFilterModelKey(paginationStateId));
}

/**
 * The callback that loads the data for usePagination. Will be called each time page, sort, filter or searchText changes.
 *
 * @callback loadDataCallback
 * @param {Object} paginationRequest - The request sent to backend, matching ePortWebLibrary/Database/Models/Pagination/QueryModel.cs
 * @returns {Promise<{rowCount: number, rows: Object[]}>}
 */
/**
 * usePagination will generate props for a DataGrid to make it use server-side pagination.
 * @param {loadDataCallback} loadData - The callback that loads the data to be displayed in the DataGrid
 * @returns {Object} The DataGrid props. Use spread operator into the DataGrid for ex: <DataGrid {...pagination} />
 */
export default function usePagination(
  loadData,
  searchText,
  defaultSortProp,
  defaultFilterProp,
  defaultPageSizeOptionsProp,
  paginationStateIdProp,
  overrideFilterProp
) {
  const [defaultSort] = useState(defaultSortProp);
  const [defaultFilter] = useState(defaultFilterProp);
  const [defaultPageSizeOptions] = useState(defaultPageSizeOptionsProp);
  const [paginationStateId] = useState(paginationStateIdProp);
  const [overrideFilter] = useState(overrideFilterProp);

  const [hasLoadedSession, setHasLoadedSession] = useState(false);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(
    getDefaultPaginationModel(defaultPageSizeOptions)
  );
  const [filterModel, setFilerModel] = useState(
    overrideFilter ?? defaultFilter ?? _defaultFilterModel
  );
  const [sortModel, setSortModel] = useState(defaultSort ? defaultSort : []);
  const [rowCount, setRowCount] = useState(0);
  const [pageSizeOptions] = useState(
    defaultPageSizeOptions ?? _defaultPageSizeOptions
  );

  const handleLoadData = useCallback(
    async (paginationModel, sortModel, filterModel, searchText) => {
      setIsLoading(true);
      const { rows, rowCount } = await loadData(
        buildQueryModel(paginationModel, sortModel, filterModel, searchText)
      );
      setRows(rows);
      setRowCount(rowCount);
      setIsLoading(false);
    },
    [loadData]
  );

  useEffect(() => {
    if (!hasLoadedSession) {
      // console.log(
      //   "pagination useEffect A !hasLoadedSession loadPaginationSession"
      // );
      const session = loadPaginationSession(paginationStateId);
      if (
        session.paginationModel ||
        session.sortModel ||
        (!!overrideFilter ? false : session.filterModel)
      ) {
        if (
          session.paginationModel &&
          session.paginationModel.pageSize &&
          !pageSizeOptions.includes(session.paginationModel.pageSize)
        ) {
          // The implementation has changed and pageSizeOptions differ
          // from what was used when we saved to sessionStorage last time
          clearPaginationSession(paginationStateId);
        } else {
          setPaginationModel(session.paginationModel);
          setSortModel(session.sortModel);
          setFilerModel(overrideFilter ?? session.filterModel);
        }
      }
      setHasLoadedSession(true);
    }
  }, [hasLoadedSession, paginationStateId, pageSizeOptions, overrideFilter]);

  useEffect(() => {
    if (hasLoadedSession) {
      // console.log("pagination useEffect B hasLoadedSession handleLoadData");
      handleLoadData(paginationModel, sortModel, filterModel, searchText);
      savePaginationSession(
        paginationStateId,
        paginationModel,
        sortModel,
        filterModel
      );
    }
  }, [
    handleLoadData,
    paginationModel,
    sortModel,
    filterModel,
    searchText,
    paginationStateId,
    hasLoadedSession,
  ]);

  const onFilterChange = useCallback(async (filterModel) => {
    // console.log("pagination onFilterChange", filterModel);
    setFilerModel(filterModel ?? _defaultFilterModel);
  }, []);

  const onSortChange = useCallback(
    async (sortModels) => {
      // console.log("pagination onSortChange", sortModels);
      setSortModel(sortModels ?? (defaultSort ? defaultSort : []));
    },
    [defaultSort]
  );

  const onPaginationChange = useCallback(
    async (paginationModel) => {
      // console.log("pagination onPaginationChange", paginationModel);
      setPaginationModel(
        paginationModel ?? getDefaultPaginationModel(defaultPageSizeOptions)
      );
    },
    [defaultPageSizeOptions]
  );

  const reloadData = useCallback(
    async () =>
      await handleLoadData(paginationModel, sortModel, filterModel, searchText),
    [handleLoadData, paginationModel, sortModel, filterModel, searchText]
  );

  const resetPagination = useCallback(() => {
    onPaginationChange(null);
    onSortChange(null);
    onFilterChange(null);
  }, [onFilterChange, onSortChange, onPaginationChange]);

  return {
    rows: rows,
    pageSizeOptions: pageSizeOptions,
    pagination: true,
    paginationModel: paginationModel,
    filterMode: "server",
    sortingMode: "server",
    paginationMode: "server",
    filterModel: filterModel,
    sortModel: sortModel,
    onFilterModelChange: onFilterChange,
    onSortModelChange: onSortChange,
    onPaginationModelChange: onPaginationChange,
    loading: isLoading,
    rowCount: rowCount,
    localUpdateRows: setRows,
    reloadData: reloadData,
    resetPagination: resetPagination,
  };
}
