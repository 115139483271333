import { useState, useEffect } from 'react';
import { useApi } from '../../../utils/Api';
import Translate from '../../../utils/Translate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UTCToLocalDateOnly } from '../../../utils/dateTimeFormat';
import { Typography } from '@mui/material';
import Preloader from '../../Preloader';

const ShowAllGdprApprovals = ({ actorId }) => {
  const [approvals, setApprovals] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const api = useApi();

  useEffect(async () => {
    await getGdrpTextApprovals();
  }, []);


  const closeAndReload = () => {
    window.history.back()
  };

  async function getGdrpTextApprovals() {
    setIsLoading(true);
    let response = null;
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/gdprapprovals`,
        false,
        'GET',
      );

      if (response.isSuccessful) {
        setApprovals(response.gdprTextApprovals);
      }

    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div>
        <Preloader />
      </div>
    )
  }

  return (
    <Dialog
      open={true}
      onClose={closeAndReload}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        {Translate.get("GdprApprovals")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {approvals.length < 1 ? Translate.get("GdprApprovalsEmptyState") :
            approvals.map((approval, index) => (
              <div key={index} style={{marginBottom: "1em", whiteSpace: "pre-line"}}>
                <Typography variant="caption">{UTCToLocalDateOnly(approval.approvalDate)}</Typography>
                <Typography variant="body2">{approval.text}</Typography>
              </div>
            ))
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowAllGdprApprovals;