import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControl,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import EPortAvatar from "../../EPortAvatar";
import Translate from "../../../utils/Translate";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import YesOrNoDialog from "../../YesOrNoDialog";
import ActorAvatar from "../../ActorAvatar";
import { useSnackbar } from "notistack";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import { actorSearch, actorCompanyTypes } from "../../../utils/actorSearch";
import { LoadingButton } from "@mui/lab";
import { accessKeys, hasAccess } from "../../../utils/userAccess";

function isSameAddress(address1, address2) {
  return (
    address1.actorAddressStreet1 === address2.actorAddressStreet1 &&
    address1.actorAddressStreet2 === address2.actorAddressStreet2 &&
    address1.actorAddressZipCode === address2.actorAddressZipCode &&
    address1.actorAddressCity === address2.actorAddressCity &&
    address1.actorAddressCountry === address2.actorAddressCountry
  );
}

function isEmptyAddress(address) {
  if (!address) return true;

  for (const property in address) {
    const isRelevantProperty =
      property !== "actorId" &&
      property !== "actorAddressId" &&
      property !== "actorAddressType" &&
      property !== "actorAddressStreet1";
    if (isRelevantProperty && address[property]) {
      return false;
    }
  }
  return true;
}

// Get the text for submit button when saving an existing actor
function getUpdateActorSubmitText(isEditOnly, hasSwitchedActor, actorId) {
  // isEditOnly determines whether we're only editing an actor or also selecting an actor for
  // a purpose. For example, consider the difference between simply editing a company via the register,
  // and editing the company when selecting it for a specific course. In the latter case we want a change
  // of actor in this dialog to change the company for the course too, and make that effect clear to the user.
  if (isEditOnly || (actorId > 0 && !hasSwitchedActor)) {
    return Translate.get("Save");
  } else {
    return Translate.get("ChooseCompany");
  }
}

const CompanyCrud = ({
  actorId,
  name,
  isEditOnly,
  isMatchingActorsDisabled,
  onActorSaved,
  onClose,
  onCancel,
  hideHeader,
}) => {
  const [matchingActor, setMatchingActor] = useState(null);
  const [submitText, setSubmitText] = useState("OK");
  const [headerText, setHeaderText] = useState(Translate.get("EditCompany"));
  const [formData, setFormData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [isBlankForm, setIsBlankForm] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [imageResponse, setImageResponse] = useState(null);
  const [
    isPostalSameAsInvoiceAddressChecked,
    setIsPostalSameAsInvoiceAddressChecked,
  ] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [paymentAgreements, setPaymenAgreements] = useState([]);
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const schema = Yup.object({
    required: Yup.string().min(1, "Required - No translation").optional(),
    email: Yup.string().email(Translate.get("InvalidEmail")).optional(),
    emailRequired: Yup.string()
      .min(1, "Required - No translation")
      .email(Translate.get("InvalidEmail"))
      .optional(),
  });

  const actorNameAutocompleteSearch = useCallback(async (searchText) => {
    var foundActors = await actorSearch(
      searchText,
      actorCompanyTypes,
      ["ActorName"],
      50
    );
    if (foundActors) {
      return foundActors.map((actor) => ({
        actorId: actor.actorId,
        actorName: actor.actorName,
        orgNo: actor.orgNo,
      }));
    }
  }, []);

  const debouncedExactMatchActorSearch = useMemo(
    () =>
      debounce(async (searchText, searchFields) => {
        const isValidSearchText =
          !!searchText &&
          typeof searchText === "string" &&
          searchText.trim() !== "";
        if (!isMatchingActorsDisabled && isValidSearchText) {
          var result = await actorSearch(
            searchText,
            actorCompanyTypes,
            searchFields,
            1,
            true
          );
          if (!!result && result.length > 0) {
            setMatchingActor(result[0]);
          }
        }
      }, 700),
    [isMatchingActorsDisabled]
  );

  async function loadPaymentAgreements() {
    if (!hasAccess(accessKeys.isMASystem)) {
      return;
    }

    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/paymentagreements`
    );
    const defaultPaymentAgreement = data.paymentAgreements.find(
      (x) => !!x.isDefaultValue
    );
    const paymentAgreements = defaultPaymentAgreement
      ? [
          {
            ePortCodeId: "defaultPaymentAgreement",
            description: `Standard (${defaultPaymentAgreement.description})`,
          },
          ...data.paymentAgreements,
        ]
      : data.paymentAgreements;
    setPaymenAgreements(paymentAgreements);
  }

  const loadActor = (id) => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${id}/as-client`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          const existingActorFormData = {
            actorId: data.actorId,
            actorName: data.actorName,
            externalActorNo: data.externalActorNo,
            orgNo: data.orgNo,
            actorEmail: data.actorEmail,
            webSite: data.webSite,
            profilePictureUrl: data.profilePictureUrl,
            observationNote: data.observationNote,
            defaultCustomerOrderNo: data.defaultCustomerOrderNo,
            paymentAgreement:
              data.paymentAgreement ?? "defaultPaymentAgreement",
            vatType: data.vatType,
            businessPhone: {
              actorId: data.actorId,
              actorPhoneNumber: data.businessPhone
                ? data.businessPhone.actorPhoneNumber
                : null,
            },
            mobilePhone: {
              actorId: data.actorId,
              actorPhoneNumber: data.mobilePhone
                ? data.mobilePhone.actorPhoneNumber
                : null,
            },
            invoiceAddress: {
              actorId: data.actorId,
              actorAddressId: data.invoiceAddress?.actorAddressId,
              actorAddressStreet1: !isNullUndefinedOrEmpty(
                data.invoiceAddress?.actorAddressStreet1
              )
                ? data.invoiceAddress.actorAddressStreet1
                : data.actorName,
              actorAddressStreet2: data.invoiceAddress?.actorAddressStreet2,
              actorAddressCity: data.invoiceAddress?.actorAddressCity,
              actorAddressZipCode: data.invoiceAddress?.actorAddressZipCode,
              actorAddressCountry: data.invoiceAddress?.actorAddressCountry,
            },
            postalAddress: {
              actorId: data.actorId,
              actorAddressId: data.postalAddress?.actorAddressId,
              actorAddressStreet1: !isNullUndefinedOrEmpty(
                data.postalAddress?.actorAddressStreet1
              )
                ? data.postalAddress.actorAddressStreet1
                : data.actorName,
              actorAddressStreet2: data.postalAddress?.actorAddressStreet2,
              actorAddressCity: data.postalAddress?.actorAddressCity,
              actorAddressZipCode: data.postalAddress?.actorAddressZipCode,
              actorAddressCountry: data.postalAddress?.actorAddressCountry,
            },
          };

          setIsPostalSameAsInvoiceAddressChecked(
            !isEmptyAddress(existingActorFormData.invoiceAddress) &&
              isSameAddress(
                existingActorFormData.invoiceAddress,
                existingActorFormData.postalAddress
              )
          );
          setIsBlankForm(false);
          setFormData(existingActorFormData);
          setDataFetched(true);
          loadPaymentAgreements();
        }
      });
  };

  const uploadImage = (actorId, imageId) => {
    return api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}UploadActorImageDatabase`,
      { actorId: actorId, imageId: imageId }
    );
  };

  function isNullUndefinedOrEmpty(str) {
    return str === undefined || str === null || str === "";
  }

  async function submitFormData(formData) {
    if (
      isMatchingActorsDisabled &&
      actorId > 0 &&
      formData.actorId !== actorId
    ) {
      // Just a sanity check, to avoid mistakes when making future changes
      throw new Error("Invalid actorId");
    }

    if (
      formData.invoiceAddress &&
      isNullUndefinedOrEmpty(formData.invoiceAddress?.actorAddressStreet1)
    ) {
      formData.invoiceAddress.actorAddressStreet1 = formData.actorName;
    }

    if (
      formData.postalAddress &&
      isNullUndefinedOrEmpty(formData.postalAddress?.actorAddressStreet1)
    ) {
      formData.postalAddress.actorAddressStreet1 = formData.actorName;
    }

    setIsSubmitLoading(true);
    delete formData.profilePictureUrl;
    formData.paymentAgreement =
      formData.paymentAgreement === "defaultPaymentAgreement"
        ? null
        : formData.paymentAgreement;
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/clients`,
      formData
    );

    if (data && data.isSuccessful) {
      if (imageResponse?.imageId) {
        await uploadImage(data.actorId, imageResponse.imageId);
      }

      if (onActorSaved) {
        await onActorSaved({
          actorId: data.actorId,
          actorName: data.actorName,
        });
      }

      if (onClose) {
        onClose();
      } else {
        closeAndReload();
      }
    }
  }

  useEffect(() => {
    const isValueInvalid = (value, validator = "required") => {
      try {
        return schema.validateSync({ [validator]: value ?? "" }) && false;
      } catch (err) {
        return err.errors[0];
      }
    };

    const checkInput = (input, value, validator) => {
      setErrors((oldValue) => ({
        ...oldValue,
        [input]: isValueInvalid(value, validator),
      }));
    };

    if (!isBlankForm) {
      checkInput("actorEmail", formData.actorEmail, "email");
      checkInput("actorName", formData.actorName, "required");
    } else {
      setSubmitDisabled(true);
    }
  }, [formData, isBlankForm]);

  useEffect(
    () => setSubmitDisabled(Object.values(errors).some((x) => x)),
    [errors]
  );

  useEffect(() => {
    if (actorId) {
      loadActor(actorId);
      setHeaderText(Translate.get("EditCompany"));
      setSubmitText(getUpdateActorSubmitText(isEditOnly, false, actorId));
    } else {
      setHeaderText(Translate.get("AddCompany"));
      setSubmitText(Translate.get("AddCompany"));
      const blankFormData = {
        actorName: name ?? null,
        externalActorNo: null,
        orgNo: null,
        actorEmail: null,
        businessPhone: { actorPhoneNumber: null },
        mobilePhone: { actorPhoneNumber: null },
        vatType: 1,
        paymentAgreement: "defaultPaymentAgreement",
        invoiceAddress: {
          actorAddressStreet2: null,
          actorAddressCity: null,
          actorAddressZipCode: null,
          actorAddressCountry: null,
        },
        postalAddress: {
          actorAddressStreet2: null,
          actorAddressCity: null,
          actorAddressZipCode: null,
          actorAddressCountry: null,
        },
      };
      setIsBlankForm(!name);
      setFormData(blankFormData);
      setDataFetched(true);
      loadPaymentAgreements();
    }
  }, [actorId, name, isEditOnly]);

  // async function handleSubmit(event) {
  //   event.preventDefault();
  //   await submitFormData(formData);
  // }

  function handleUseMatchingActor() {
    setDataFetched(false);
    loadActor(matchingActor.actorId);
    setHeaderText(Translate.get("EditCompany"));
    setSubmitText(getUpdateActorSubmitText(isEditOnly, true, actorId));
    setMatchingActor(null);
  }

  function handleActorNameAutocompleteChange(actor) {
    if (actor && actor.actorId > 0) {
      setDataFetched(false);
      loadActor(actor.actorId);
      setHeaderText(Translate.get("EditCompany"));
      setSubmitText(getUpdateActorSubmitText(isEditOnly, true, actorId));
    } else {
      setFormData({
        ...formData,
        actorName: actor?.actorName,
      });
      setIsBlankForm(false);
    }
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }

    if (onClose) {
      onClose();
    } else {
      closeAndReload();
    }
  }

  function updateInvoiceAddress(updatedInvoiceAddress) {
    setFormData({
      ...formData,
      invoiceAddress: updatedInvoiceAddress,
      postalAddress: isPostalSameAsInvoiceAddressChecked
        ? { ...updatedInvoiceAddress }
        : formData.postalAddress,
    });
  }

  function handleCheckSameAddress(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      setFormData({
        ...formData,
        postalAddress: { ...formData.invoiceAddress },
      });
    }
    setIsPostalSameAsInvoiceAddressChecked(isChecked);
  }

  const deleteImage = async (actorId) => {
    const response = await api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}DeleteActorImageDatabase`,
      { actorId: actorId }
    );
    if (response?.isSuccessful !== false) {
      delete formData.profilePictureUrl;
      setFormData({ ...formData });
    }
  };

  // Using this to avoid validating the potentially empty
  // name value when opening an empty form the first time
  const isActorNameBlank =
    !("actorName" in formData) || typeof formData?.actorName === typeof null;

  return (
    <>
      {!dataFetched && (
        <>
          <Preloader />
          {!hideHeader && <DialogTitle>{headerText}</DialogTitle>}
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {dataFetched && (
        <>
          {!hideHeader && <DialogTitle>{headerText}</DialogTitle>}
          <DialogContent
            sx={{ width: { md: "900px" } }}
            dividers={true}
            className="stdDialogContent dialogOverlay"
          >
            <form className="twoColForm">
              <div className="formAvatar">
                <div className="smallHeader">{Translate.get("Logotype")}</div>
                <EPortAvatar
                  avatarWidth={160}
                  avatarHeight={"auto"}
                  variant="rounded"
                  //avatarWidth={160}
                  //avatarHeight={160}
                  fontSize={72}
                  displayName={formData.actorName ? formData.actorName : "?"}
                  pictureUrl={formData.profilePictureUrl + ""}
                  onImageUploaded={(resp) => setImageResponse(resp)}
                  onDeleteImage={() => deleteImage(actorId)}
                  imageType={"company"}
                />
              </div>
              <div className="stdForm">
                <div className="smallHeader">
                  {Translate.get("CompanyInfo")}
                </div>
                <div>
                  <AutocompleteSearchField
                    placeholder={Translate.get("Name")}
                    label={Translate.get("Name")}
                    value={{ actorName: formData.actorName ?? "" }}
                    onValueChange={handleActorNameAutocompleteChange}
                    getOptionLabel={(option) => option?.actorName ?? ""}
                    renderOption={(option) => (
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        orgNo={option?.orgNo}
                        email={option?.email}
                        companies={option?.companies}
                      />
                    )}
                    keyPropName="actorId"
                    search={
                      !formData || !formData.actorId || formData.actorId <= 0
                        ? actorNameAutocompleteSearch
                        : null
                    }
                    createNewValue={(text) => ({
                      actorName: text,
                    })}
                    textFieldProps={{
                      required: true,
                      error: !isActorNameBlank && !!errors["actorName"],
                      helperText: !isActorNameBlank && errors["actorName"],
                    }}
                    hideOptionsFilter={
                      formData.actorId > 0 &&
                      ((option) => option.actorId === formData.actorId)
                    }
                  />
                  <TextField
                    fullWidth
                    value={formData.orgNo ?? ""}
                    onChange={(event) => {
                      setFormData({ ...formData, orgNo: event.target.value });
                      setIsBlankForm(false);
                      debouncedExactMatchActorSearch(event.target.value, [
                        "OrgNo",
                      ]);
                    }}
                    label={Translate.get("OrgNo")}
                    helperText={!isActorNameBlank && errors["actorName"] && " "}
                  />
                </div>
                <div className="smallHeader">
                  {Translate.get("ContactInformation")}
                </div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.actorEmail ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        actorEmail: event.target.value,
                      });
                      setIsBlankForm(false);
                      debouncedExactMatchActorSearch(event.target.value, [
                        "ActorEmail",
                      ]);
                    }}
                    label={Translate.get("Email")}
                    error={errors["actorEmail"] && true}
                    helperText={errors["actorEmail"]}
                  />
                  <TextField
                    fullWidth
                    value={formData.businessPhone?.actorPhoneNumber ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        businessPhone: {
                          actorPhoneNumber: event.target.value,
                          actorId: formData.actorId,
                        },
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("PhoneNbr")}
                    helperText={errors["actorEmail"] && " "}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.webSite ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        webSite: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("WebPage")}
                  />
                  <TextField
                    fullWidth
                    value={formData.mobilePhone?.actorPhoneNumber ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        mobilePhone: {
                          actorPhoneNumber: event.target.value,
                          actorId: formData.actorId,
                        },
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("MobilePhone")}
                  />
                </div>
                <div className="smallHeader">
                  {Translate.getActorAddressType(1)}
                </div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.invoiceAddress?.actorAddressStreet2 ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.invoiceAddress,
                        actorAddressStreet2: event.target.value,
                        actorId: formData.actorId,
                      };
                      updateInvoiceAddress(updatedAddress);
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("StreetAddress")}
                  />

                  <TextField
                    fullWidth
                    value={formData.invoiceAddress?.actorAddressCity ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.invoiceAddress,
                        actorAddressCity: event.target.value,
                        actorId: formData.actorId,
                      };
                      updateInvoiceAddress(updatedAddress);
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("City")}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.invoiceAddress?.actorAddressZipCode ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.invoiceAddress,
                        actorAddressZipCode: event.target.value,
                        actorId: formData.actorId,
                      };
                      updateInvoiceAddress(updatedAddress);
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("ZipCode")}
                  />
                  <TextField
                    fullWidth
                    value={formData.invoiceAddress?.actorAddressCountry ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.invoiceAddress,
                        actorAddressCountry: event.target.value,
                        actorId: formData.actorId,
                      };
                      updateInvoiceAddress(updatedAddress);
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("Country")}
                  />
                </div>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <div className="smallHeader">
                    {Translate.getActorAddressType(3)}
                  </div>
                  <Box>
                    <FormControlLabel
                      classes={{ label: "smallHeader camelCase" }}
                      control={
                        <Checkbox
                          sx={{ marginTop: "8px" }}
                          onChange={(event) => handleCheckSameAddress(event)}
                          checked={isPostalSameAsInvoiceAddressChecked}
                        />
                      }
                      labelPlacement="start"
                      label={Translate.get("SameAsInvoiceAddress")}
                    />
                  </Box>
                </Box>
                <div>
                  <TextField
                    fullWidth
                    disabled={isPostalSameAsInvoiceAddressChecked}
                    value={formData.postalAddress?.actorAddressStreet2 ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressStreet2: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("StreetAddress")}
                  />

                  <TextField
                    fullWidth
                    disabled={isPostalSameAsInvoiceAddressChecked}
                    value={formData.postalAddress?.actorAddressCity ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressCity: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("City")}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    disabled={isPostalSameAsInvoiceAddressChecked}
                    value={formData.postalAddress?.actorAddressZipCode ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressZipCode: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("ZipCode")}
                  />
                  <TextField
                    fullWidth
                    disabled={isPostalSameAsInvoiceAddressChecked}
                    value={formData.postalAddress?.actorAddressCountry ?? ""}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressCountry: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("Country")}
                  />
                </div>
                <div className="smallHeader">{Translate.get("Other")}</div>
                {hasAccess(accessKeys.isMASystem) && (
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="vat-type-label">
                        {Translate.get("VatType")}
                      </InputLabel>
                      <Select
                        label={Translate.get("VatType")}
                        labelId="vat-type-label"
                        value={formData.vatType ?? ""}
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            vatType: event.target.value,
                          });
                          setIsBlankForm(false);
                        }}
                      >
                        {[1, 2, 3].map((vatType) => (
                          <MenuItem value={vatType}>
                            {Translate.getVatType(vatType)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {hasAccess(accessKeys.isMASystem) && (
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="payment-agreement-label">
                        {Translate.get("PaymentTerm")}
                      </InputLabel>
                      <Select
                        label={Translate.get("PaymentTerm")}
                        labelId="payment-agreement-label"
                        value={
                          formData.paymentAgreement ?? "defaultPaymentAgreement"
                        }
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            paymentAgreement: event.target.value,
                          });
                          setIsBlankForm(false);
                        }}
                      >
                        {paymentAgreements.map((paymentAgreement) => (
                          <MenuItem value={paymentAgreement.ePortCodeId}>
                            {paymentAgreement.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div>
                  <TextField
                    fullWidth
                    value={formData.defaultCustomerOrderNo ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        defaultCustomerOrderNo: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("DefaultCustomerOrderNo")}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    value={formData.observationNote ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        observationNote: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("ObservationNote")}
                    inputProps={{ maxLength: 255 }}
                  />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>{Translate.get("Cancel")}</Button>
            <LoadingButton
              className="stdLightLoader"
              loading={isSubmitLoading}
              disabled={submitDisabled}
              onClick={() => submitFormData(formData)}
            >
              {submitText}
            </LoadingButton>
          </DialogActions>
          {/* YesOrNoDialog for asking if we should use an existing matching actor instead */}
          <YesOrNoDialog
            open={!!matchingActor && matchingActor.actorId !== formData.actorId}
            title={Translate.get("UseMatchingCompanyTitle")}
            content={
              !!matchingActor ? (
                <>
                  {Translate.get("UseMatchingCompanyQuestion")}
                  <Box marginTop="24px">
                    <ActorAvatar
                      name={matchingActor.actorName}
                      email={matchingActor.email}
                      extraFields={[
                        matchingActor.orgNo,
                        matchingActor.externalActorNo,
                        matchingActor.parentActors
                          ? [
                              ...matchingActor.parentActors.map(
                                (parent) => parent.parentActor.actorName
                              ),
                            ]
                          : [],
                      ]}
                      profilePictureUrl={matchingActor.profilePictureUrl}
                      vertical
                    />
                  </Box>
                </>
              ) : (
                ""
              )
            }
            onNo={() => setMatchingActor(null)}
            onYes={handleUseMatchingActor}
            noText={Translate.get("No")}
            yesText={Translate.get("Yes")}
          />
          {/* Allt innehåll */}
        </>
      )}
    </>
  );
};
export default CompanyCrud;
