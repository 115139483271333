import { Box, Grid, Stack, Tooltip } from "@mui/material";
import ButtonWithSpinner from "../ButtonWithSpinner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
import Translate from "../../utils/Translate";
import { Fragment } from "react";

export const actionStatus = {
  notDone: 1,
  done: 2,
  failed: 3,
  indeterminate: 4,
};

function getDefaultTooltip(status) {
  switch (status) {
    case actionStatus.done:
      return Translate.get("Ready");
    case actionStatus.failed:
      return Translate.get("Failed");
    case actionStatus.notDone:
      return Translate.get("NotDone");
    case actionStatus.indeterminate:
    default:
      return "";
  }
}

function getIcon(status) {
  switch (status) {
    case actionStatus.done:
      return <CheckCircleOutlineIcon />;
    case actionStatus.failed:
      return <WarningIcon />;
    case actionStatus.indeterminate:
      return <RemoveCircleOutlineIcon />;
    case actionStatus.notDone:
      return <ScheduleIcon />;
    default:
      return <Box minWidth="36px" minHeight="24px"></Box>;
  }
}

function getIconColor(status) {
  switch (status) {
    case actionStatus.done:
      return "success";
    case actionStatus.failed:
      return "warning";
    case actionStatus.indeterminate:
      return "blue";
    case actionStatus.notDone:
    default:
      return "";
  }
}

function ActionRow({
  status,
  tooltip,
  description,
  buttonLabel,
  action,
  disabled,
  buttonDisabledTooltip,
}) {
  return (
    <Grid item container className="afterPickAction" alignItems="center">
      <Grid item xs={2} sm={1}>
        <Stack direction="row" alignItems="center">
          {
            <Tooltip
              title={tooltip ?? getDefaultTooltip(status)}
              className={"iconbox " + getIconColor(status)}
            >
              {getIcon(status)}
            </Tooltip>
          }
        </Stack>
      </Grid>
      <Grid item xs={7} sm={8}>
        <Box>{description}</Box>
      </Grid>
      <Grid item xs={3} sm={3}>
        <Stack direction="row" justifyContent="flex-end">
          {buttonDisabledTooltip && disabled ? (
            <Tooltip title={buttonDisabledTooltip}>
              <ButtonWithSpinner onClick={action} disabled={disabled}>
                {buttonLabel}
              </ButtonWithSpinner>
            </Tooltip>
          ) : (
            <ButtonWithSpinner onClick={action} disabled={disabled}>
              {buttonLabel}
            </ButtonWithSpinner>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default function AfterPickAction({ altActions, ...rest }) {
  return (
    <Grid container className="afterPickActionContainer" alignItems="center">
      <ActionRow {...rest} />
      {altActions &&
        altActions.map((alt, index) => (
          <Fragment key={index}>
            <Grid item xs={12}>
              <Box className="afterPickActionAltDivider">
                <span>{Translate.get("Or").toLowerCase()}</span>
              </Box>
            </Grid>
            <ActionRow {...alt} />
          </Fragment>
        ))}
    </Grid>
  );
}
