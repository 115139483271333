import { Delete, Info, Star } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Translate from "../utils/Translate";
import { useEffect, useState } from "react";
import { useApi } from "../utils/Api";
import { buildQueryModel } from "../utils/pagination";

export default function DataGridEasyFilters({
  filters,
  currentFilterModel,
  onSelectFilter,
  summaryFilterIds: summaryFilterIdsProp,
}) {
  const [selectedFilterId, setSelectedFilterId] = useState(null);
  const [rowCountSummaries, setRowCountSummaries] = useState([]);
  const [summaryFilterIds] = useState(summaryFilterIdsProp); // Doesn't support changed filters currently
  const api = useApi();

  useEffect(() => {
    if (selectedFilterId) {
      const selectedFilter = filters.find((f) => f.id === selectedFilterId);
      const selectedFilterModel = selectedFilter.filterModel;
      if (
        selectedFilterModel.items.length !== currentFilterModel.items.length
      ) {
        setSelectedFilterId(null);
      }
      const hasFilterModelChanged = selectedFilterModel.items.some(
        (x) =>
          !currentFilterModel.items.some(
            (y) =>
              y.field === x.field &&
              y.operator === x.operator &&
              (Array.isArray(y.value) &&
              Array.isArray(x.value) &&
              y.value.length === x.value.length
                ? y.value
                    .map((yval, index) => yval === x.value[index])
                    .every((result) => !!result)
                : y.value === x.value)
          )
      );
      if (hasFilterModelChanged) {
        setSelectedFilterId(null);
      }
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (!summaryFilterIds || summaryFilterIds.length === 0) {
      return;
    }

    const threeMinuteMillis = 3 * 60 * 1000;
    const queryModelEntries = filters
      .filter((x) => summaryFilterIds.includes(x.id))
      .map((x) => [
        x.id,
        buildQueryModel(null, x.sortModel, x.filterModel, null),
      ]);
    const request = { queryModelsByKey: Object.fromEntries(queryModelEntries) };
    const url = `${process.env.REACT_APP_MAIN_URL}orders/get-row-counts`;

    async function updateRowCounts() {
      const response = await api.fetchWithOverride(
        url,
        request,
        "POST",
        () => true, // Response always ok, we ignore errors
        null,
        null,
        null,
        false,
        true,
        false
      );
      // console.log("ROW COUNTS", response);
      if (response && response.isSuccessful) {
        const rowCounts = Object.entries(response.rowCountsByKey)
          .map(([key, rowCount]) => {
            const filter = filters.find((f) => f.id == key);
            if (filter) {
              return { id: filter.id, name: filter.name, rowCount };
            } else {
              return null;
            }
          })
          .filter((x) => !!x && x.rowCount > 0);
        setRowCountSummaries(rowCounts);
      }
    }

    updateRowCounts(); // Call once immediately, then later in setInterval
    const intervalId = setInterval(updateRowCounts, threeMinuteMillis);
    return () => clearInterval(intervalId);
  }, [api, summaryFilterIds]);

  function handleFilterSelected(newFilterId) {
    setSelectedFilterId(newFilterId);
    const selectedFilter = filters.find((f) => f.id === newFilterId);
    onSelectFilter(selectedFilter);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {filters &&
                filters.length > 0 &&
                filters.map((f) => `${f.name}: ${f.description}`).join("\n\n")}
            </div>
          }
        >
          <IconButton>
            <Info color="primary" />
          </IconButton>
        </Tooltip>

        <FormControl sx={{ minWidth: "200px" }}>
          <InputLabel id="easy-filter">{Translate.get("StdFilter")}</InputLabel>
          <Select
            labelId="easy-filter"
            id="easy-filter"
            value={selectedFilterId ?? ""}
            label={Translate.get("StdFilter")}
            onChange={(event) => handleFilterSelected(event.target.value)}
          >
            {filters.map((filter) => (
              <MenuItem key={filter.id} value={filter.id}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => onSelectFilter(null)}
          variant="outlined"
          disabled={currentFilterModel?.items?.length === 0}
          startIcon={<Delete />}
        >
          {Translate.get("Purge")}
        </Button>

        {/* <Button
        variant="contained"
        onClick={() => setOpenMyFilters(true)}
        startIcon={<Star />}
        style={{ marginLeft: "1em" }}
      >
        {Translate.get("MyFilters")}
      </Button> */}
      </Stack>

      {rowCountSummaries && rowCountSummaries.length > 0 && (
        <Stack direction="row" flexWrap="wrap" columnGap={2} paddingLeft={1}>
          {rowCountSummaries.map((x) => (
            <Button
              size="small"
              key={x.name}
              onClick={() => handleFilterSelected(x.id)}
            >
              {`${x.name} (${x.rowCount})`}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
