export default function QRCodePreview({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size}
      height={size}
    >
      <rect
        width="100"
        height="100"
        fill="black"
        stroke="white"
        stroke-width="5"
      />
      <rect x="8" y="8" width="84" height="84" fill="white" />
      <text
        x="50"
        y="50"
        font-size="24"
        fill="black"
        text-anchor="middle"
        dominant-baseline="middle"
      >
        QR
      </text>
    </svg>
  );
}
